.Skejnav {    
    background-color: #83aae5;
}

.ProfileMenuPair {
  display: flex;
}

.Menu {
    text-align: right;
}
/* @media only screen and (max-width: 991px) {
    .buffer {
        width: 33%;

    }
} */


 .footer {
    margin: auto;
    margin-top: 10px;
    width: 100%;
    font-size: 10px;
    position: fixed;
    text-align: center;
    background-color: #f5f5f5;
    bottom: 0;
    height: 30px;
 }

@media only screen and (max-width: 600px) {
.footer {
    display: none;
  }
}

.footerBlock {
      display: inline-block;
      margin: auto;
    }

.FooterItem{
  margin-left: 10px;
}

.NextBtn {
    margin-top: 20px;
    margin-bottom: 2px;
    min-width: 150px;
  }

.BackBtn {
    margin-top: 2px;
    margin-bottom: 20px;
    min-width: 150px;
  }


@media only screen and (max-width: 600px) {
  .ViewSelDates{
    width: 80%;
  }
  .NextBtn{
    width: 80%;
  }
  .BackBtn {
    width: 80%;
  }
}

.LocationBoxModalDiv {
  text-align: center;
  height: 100px;
  overflow: scroll;
  overflow-x: hidden;
  padding: 2px;
}

.LocationBoxesDiv {
  text-align: center;
  height: 100px;
  overflow: scroll;
  overflow-x: hidden;
  width: 70%;
  margin-left: 15%;
  padding: 2px;
}

@media only screen and (min-width: 500px) {
  .LocationBoxesDiv {
    width: 400px;
    margin-left: calc(50% - 200px);
  }
}


.LocationBox {
  display: flex;
  justify-content: space-between;
  border-radius: 15px;
  background: #73AD21;
  padding: 2px;
  width: 100%;
  height: 30px;
  margin-top: 2px;
  margin-bottom: 2px;
  color: white;
  padding-left: 10px;
}

.xBtn {
  display: inline-block;
  background-color: transparent;
  border: none;
  color: white;
  text-align: right;
}

.radioBtn {
  display: inline-block;
  margin-top: 6px;
  margin-right: 3px;
}

.EntryBox {
  margin: auto;
/*     display: inline-block; */
}

.CopyBox {
    background-color: #575f61;
    width: 300px;
    margin: auto;
    overflow: hidden;
    color: white;
    white-space: nowrap;
    padding: 3px;

/*     display: inline-block; */
}

.EventTable {
  margin: auto;
}

.EventTableHdr {
  border-bottom : solid 3px black;
  background: white;
  color: black;
  font-weight: bold;
  margin: auto
}

.EventTableCell {
  padding: 10px;
  border: solid 1px gray;
  background: white;
}

/* .CalendarDiv {
  width: 100%;
  margin: auto;
  background-color: transparent;
} */

.Calendar {
  background-color: transparent;
}

.DateSpan {
   font-weight: bold;
   margin-left: 4px;
   margin-right: 4px;
}

.SubHeading {
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;
    font-size: 24px;

}



.AttendeeBox{
  display: inline-block;
  margin:auto;
  overflow: scroll;
  overflow-x: hidden;
  max-height: 160px;
  width: 40%;

}

@media screen and (max-width: 600px) {
.AttendeeBox {
    width: 100%;
  }
}

.AttendeeCard{
  border-radius: 15px;
  background: #73AD21;
  width: 90%;
  color: white;
  padding-left: 10px;
  margin:auto;
  margin-top: 5px;
  padding: 5px;
  height: 40px;
  display: flex; 
  justify-content: space-between;
}

.Cardcol1{
  margin-left: 5px;
}
.Cardcol2{
  margin-right: 5px;
}

.ModalBtn {
    margin-top: 5px;
    min-width: 40%;
}

@media only screen and (max-width: 600px) {
    .ModalBtn {
        min-width: 100%;
    }
}

.DateBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  background: #73AD21;
  padding: 5px;
  height: 40px;
  min-width: 50%;
  max-width: 100%;
  width: auto;
  margin: auto;
  margin-top: 5px;
  color: white;
  padding-left: 10px;
 
}

.DateBoxContainer{
  margin: auto;
}

.DateBoxCheckDiv{
  width:33%;
  text-align: right;
}
.DateCheckbox {

    width:20px;
    height:20px;
    background:white;
    border-radius:2px;
    border:2px solid #555;
    margin-right: 10px;
}

.DateBoxText{
  align-content: flex-start;
  text-align: left;
  width: 33%;
  font-size: 18px;
  overflow-wrap: none;
}

.DateBoxDates{
  width: 33%;
}

.SelectedDateBox {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #dee5ea;
  padding-bottom: 5px;
  margin-bottom: 5px;
  width: 100%;
}

.SelectedDateBoxItem {
  width: 33%;
  align-self: center;
}

.DeleteIcon:hover{
  cursor: pointer;
}


.ModalBtn {
    margin-top: 5px;
    min-width: 40%;
}


@media only screen and (max-width: 600px) {
    .ModalBtn {
        min-width: 100%;
    }
}

.ConfirmDeleteBtn {
    margin-left: 5px;
    margin-right: 5px;
}
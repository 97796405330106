.Home {
    background-color: #dee5ea;
    height: 100%;
}

.DividerLine {
      border-top: 3px solid #bbb;
      width: 66%;
}

.MainHeading {
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;
    font-size: 32px;
    font-weight: bold; 
}

.SubHeading {
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;
    font-size: 24px;

}

.WelcomePageStart {
    margin-bottom: 10vh;
    min-height: 100%;
}

.PlannerPageStart {
    text-align: center;
    min-height: 100%;
    padding-bottom: 50px;
}

.Textbox {
    margin: auto;
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;
    max-width: 66%;
}

@media only screen and (max-width: 600px) {
    .Textbox {
        margin: auto;
        margin-top: 2.5vh;
        margin-bottom: 2.5vh;
        padding-left: 20px;
        padding-right: 20px;
        max-width: 100%;
    }
}

.EgBox {
    display: inline-block;
    text-align: left;
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;
    color: grey;
}

.EntryBox {
/*     display: inline-block; */
}

.CodeEntry{
    height: 20%;
    margin-bottom: 100px;
    display: block;
}

.AboutBox{
    max-width: 500px;
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
}

@media only screen and (max-width: 600px) {
  .AboutBox {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.Settings{
    margin: auto;
    /* width: min-content; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 30px;
    padding-right: 30px;
}

.SettingsItem{
    margin:auto;
    width: 100%;
}

/* @media only screen and (max-width: 600px) {
    .Settings{
    }
} */

.EventHomeCopyBox{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.EventHomeCopyBoxItem{
    padding-right: 10px;
}

.EventListItem{
    display: flex;
    justify-content: space-between;
    align-items: center;
}